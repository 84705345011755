.tracker {
    h1 {
        font-size: 3.6rem;
        width: 16rem;
        font-weight: 400;
    }

    .counter {
        display: flex;
        align-items: center;
        font-size: 4.8rem;
        color: black;
        font-family: "Bitter", Helvetica, sans-serif;
    }
}

.tracker-group {
    margin-left: 5rem;
    margin-top: 8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 98rem;
}

@media screen and (max-width: 780px) {
    .tracker {
        margin-top: 3rem;
        display: flex;
        align-items: center;
        

        h1 {
            font-size: 2.4rem;
            width: 20.1rem;
        }

        .counter {
            margin-left: 6rem;
            font-size: 3.6rem;
        }
    }

    .tracker-group {
        width: 33rem;
        margin-left: 2rem;
        margin-top: 5rem;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}