.misSec {
    display: flex;
    flex-direction: column;
    margin-left: 5rem;
    margin-top: 8rem;
    h1 {
        font-size: 4.8rem;
        font-weight: 400;
    }

    p,a {
        font-size: 3.6rem;
    }

    p {
        width: 78rem;
        margin-top: 6rem;
    }

    a {
        margin-top: 4rem;
        color: $code-color;
        text-decoration: none;
        transition: all .4s cubic-bezier(0.2, 0.6, 0.2, 1);

        &:hover {
            margin-left: 1rem;
            transition: all .4s cubic-bezier(0.2, 0.6, 0.2, 1);
        }
    }

    @media screen and (max-width: 780px){
        margin-left: 2rem;
        margin-top: 4rem;

        h1 {
            font-size: 2.4rem;
        }

        p {
            font-size: 1.8rem;
            width: 33rem;
        }

        a {
            font-size: 2.4rem;
            &:hover {
                margin-left: 0;
                transition: none;
            }
        }
    }
}