footer {
    margin-top: auto;
    width: 100%;
    height: 10rem;
    display: flex;
    align-items: center;

    button {
        font-size: 1.8rem;
        cursor: pointer;
        margin-left: 5rem;
        border: none;
        background: none;
        font-family: inherit;
        transition: all .4s cubic-bezier(0.2, 0.6, 0.2, 1);

        &:hover {
            font-size: 2.4rem;
            transition: all .4s cubic-bezier(0.2, 0.6, 0.2, 1);
        }
    }

    input {
        display: none;
    }
    .file-upload {
        cursor: pointer;
        margin-left: 3rem;
        font-size: 1.8rem;
        transition: all .4s cubic-bezier(0.2, 0.6, 0.2, 1);

        &:hover {
            font-size: 2.4rem;
            transition: all .4s cubic-bezier(0.2, 0.6, 0.2, 1);
        }
    }

    p {
        color: $code-color;
        font-size: 2.4rem;
        margin-left: 3rem;
    }

    a {
        margin-left: auto;
        margin-right: 4rem;
        font-size: 2.4rem;
        color: black;
        text-decoration: none;
        transition: all .4s cubic-bezier(0.2, 0.6, 0.2, 1);

        &:hover {
            margin-right: 5rem;
            transition: all .4s cubic-bezier(0.2, 0.6, 0.2, 1);
        }
    }
}

@media screen and (max-width: 780px) {
    footer {
        button, .file-upload, p {
            font-size: 1.2rem;
            &:hover {
                font-size: 1.2rem;
                transition: none;
            }
        }

        a {
            font-size: 1.8rem;
            margin-right: 2rem;

            &:hover {
                margin-right: 2rem;
                transition: none;
            }
        }
    }
}