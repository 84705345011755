html {
    font-size: calc(100vw / 1440 * 10);
    @media screen and (max-width: 780px){
        font-size: calc(100vw / 414 * 10);
    }
}

body {
    font-family: "Bitter", Helvetica, sans-serif;
}

* {
    margin: 0;
    padding: 0;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}