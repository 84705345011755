#main-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;

    h1 {
        margin-top: 6.4rem;
        margin-bottom: 15rem;
        font-size: 9.6rem;
        font-weight: 400;
    }

    #specialisation-list {
        display: flex;
        flex-direction: column;
        align-items: center;

        a {
            margin-top: 3rem;
            transition: all .4s cubic-bezier(0.2, 0.6, 0.2, 1);
            font-size: 3.6rem;
            color: #000000;
            text-decoration: none;
            &:hover {
                margin-left: 3rem;
                padding-bottom: 3rem;
                transition: all .4s cubic-bezier(0.2, 0.6, 0.2, 1);
            }
        }
    }

    #sgn {
        margin-top: auto;
        margin-left: auto;
        margin-bottom: 4rem;
        margin-right: 4rem;
        font-size: 2.4rem;
        color: black;
        text-decoration: none;
        transition: all .4s cubic-bezier(0.2, 0.6, 0.2, 1);

        &:hover {
            padding-right: 1rem;
            padding-bottom: 1rem;
            transition: all .4s cubic-bezier(0.2, 0.6, 0.2, 1);
        }
    }

    @media screen and (max-width: 780px) {
        h1 {
            margin-top: 4rem;
            margin-bottom: 8rem;
            font-size: 6.4rem;
        }

        #specialisation-list {
            height: 33rem;
            justify-content: space-between;

            a {
                font-size: 2.4rem;
                width: 21rem;
                margin: 0;

                &:hover {
                    margin-left: 0;
                    padding-bottom: 0;
                    transition: none;
                }
            }
        }

        #sgn {
            font-size: 1.8rem;
            margin-left: auto;
            margin-right: auto;

            &:hover {
                padding-right: 0;
                padding-bottom: 0;

                transition: none;
            }
        }
    }
}