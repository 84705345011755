.course-description {
    display: flex;
    align-items: center;
    .text {
        margin-top: 2rem;
        margin-left: 5rem;
        display: flex;
        font-size: 2.4rem;
        width: 98rem;
        justify-content: space-between;
        border-bottom: $line;
        padding-bottom: 1rem;
    }

    a {
        color: $code-color;
    }

    .delete {
        margin-left: 1rem;
        cursor: pointer;
        width: 2.3rem;
        height: 2.3rem;
        background: url("../images/delete.svg") no-repeat center;
        background-size: cover;
        transition: all .3s ease-in-out;

        &:hover {
            width: 2.5rem;
            height: 2.5rem;
            transition: all .3s cubic-bezier(0.2, 0.6, 0.2, 1);
        }
    }
}

.leaving {
    margin-left: -103rem;
    transition: all .3s ease-in-out;
}

@media screen and (max-width: 780px) {
    .course-description {
        .text {
            margin-left: 2rem;
            font-size: 1.2rem;
            width: 33rem;
        }

        .delete {
            width: 2rem;
            height: 2rem;
            &:hover {
                width: 2.5rem;
                height: 2.5rem;
                transition: all .3s cubic-bezier(0.2, 0.6, 0.2, 1);
            }
        }
    }
}