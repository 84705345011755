header {
    margin-top: 2rem;
    margin-left: 5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    a {
        font-size: 6.4rem;
        color: #000000;
        text-decoration: none;
        transition: all .4s cubic-bezier(0.2, 0.6, 0.2, 1);

        &:hover {
            margin-left: 2rem;
            transition: all .4s cubic-bezier(0.2, 0.6, 0.2, 1);
        }
    }

    p {
        margin-left: 4rem;
        font-size: 1.8rem;
    }

    @media screen and (max-width: 780px) {
        margin-left: 2rem;
        flex-direction: column;
        align-items: flex-start;

        a {
            font-size: 4.8rem;
            &:hover {
                margin-left: 0;
                transition: none;
            }
        }

        p {
            margin: 0;
        }
    }
}